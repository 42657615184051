const loadGoogleMaps = async () => {
  const initFunction = () => {};
  window.initFunction = initFunction
  const resolveCb = () => {
    return window.google;
  };
  const rejectCb = () => {
    new Error("google-maps: authentication error");
  };
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&language=es-419&format=jpg&callback=initFunction`;
  script.async = true;
  script.onerror = (e) => console.error(e);
  document.head.appendChild(script);
  window["_dk_google_maps_loader_cb"] = resolveCb;
  window["gm_authFailure"] = rejectCb;
};

export default loadGoogleMaps;
