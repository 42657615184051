import { useEffect, useState, useRef, FC } from 'react';
import { loadGoogleMaps } from '.';

const useLoadGoogleMaps = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (window.google !== undefined) setIsLoaded(true);
    if (window.google === undefined) {
      loadGoogleMaps();
      interval.current = setInterval(() => {
        if (window.google !== undefined) {
          setIsLoaded(true);
          interval.current && clearInterval(interval.current);
        }
      }, 500);
    }
    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, []);

  return isLoaded;
}

export const LoadGoogleMaps: FC<{isLoadedCallback: Function}> = ({
  isLoadedCallback
}) => {
  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (window.google !== undefined) isLoadedCallback && isLoadedCallback(true);
    if (window.google === undefined) {
      loadGoogleMaps();
      interval.current = setInterval(() => {
        if (window.google !== undefined) {
          isLoadedCallback && isLoadedCallback(true);
          interval.current && clearInterval(interval.current);
        }
      }, 500);
    }
    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, []);

  return null;
}

export default useLoadGoogleMaps;

