export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const isDoubleSpace = e.currentTarget.value.endsWith(" ") && e.key === " ";

  if (
    (e.key >= "0" && e.key <= "9") ||
    specialCharacters.test(e.key) ||
    isDoubleSpace
  ) {
    e.preventDefault();

    e.currentTarget.classList.remove("shake-animation");
    void e.currentTarget.offsetWidth;
    e.currentTarget.classList.add("shake-animation");

    setTimeout(() => {
      e.currentTarget.classList.remove("shake-animation");
    }, 300);
  }
};

export const handleNumberKeyPress = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (!(e.key >= "0" && e.key <= "9")) {
    e.preventDefault();

    e.currentTarget.classList.remove("shake-animation");
    void e.currentTarget.offsetWidth;
    e.currentTarget.classList.add("shake-animation");

    setTimeout(() => {
      e.currentTarget.classList.remove("shake-animation");
    }, 300);
  }
};

export const handleNoSpaceKeyPress = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (e.key === " ") {
    e.preventDefault();

    e.currentTarget.classList.remove("shake-animation");
    void e.currentTarget.offsetWidth;
    e.currentTarget.classList.add("shake-animation");

    setTimeout(() => {
      e.currentTarget.classList.remove("shake-animation");
    }, 300);
  }
};
